// ContractorEditForm.tsx

import React, { useState, useEffect } from "react";
import { Link, useRouteLoaderData, useParams, useNavigate } from "react-router-dom";
import Select from "react-select";

import { CountryInput, CountryCodeInput } from "./CountryInput";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI"; // Ensure correct import
import Tag from "./Tags";
import { Loader } from "./loader";

import { ADMIN_URL } from "../config";
import { ComunaAPI } from "../managers/ComunaAPI";

import juniorBadge from "../img/icons/junior-badge.png";
import midBadge from "../img/icons/mid-badge.png";
import seniorBadge from "../img/icons/senior-badge.png";
import leadBadge from "../img/icons/lead-badge.png";

import closeIcon from "../img/icons/x.png";
import addImageIcon from "../img/icons/image-plus.png";


const ContractorEditForm: React.FC = () => {
    const { contractor_id } = useParams<{ contractor_id: string }>();
    const { contractors } = useRouteLoaderData("contractors_list") as { contractors: any[] };
    const navigate = useNavigate();

    const [skillOptions, setSkillOptions] = useState<{ label: string; value: number }[]>([]);
    const [selectedSkills, setSelectedSkills] = useState<{ label: string; value: number }[]>([]);

    const [contractor, setContractor] = useState<any>({
        first_name: "",
        last_name: "",
        title: "",
        email: "",
        phone_number: "",
        phone_country_indicator: "",
        salary_expectation: "",
        english_level: "1",
        seniority: "1",
        website: "",
        linkedin: "",
        cv: null,
        profile_picture: null,
        country_location: "",
        city_location: "",
        top_candidate: false,
        skills: [],
        note_for_client: "",
    });

    const [validations, setValidations] = useState<{
        [key: string]: boolean;
    }>({
        first_name: true,
        last_name: true,
        title: true,
        email: true,
        phone_number: true,
        phone_country_indicator: true,
        salary_expectation: true,
        english_level: true,
        seniority: true,
        website: true,
        linkedin: true,
        cv: true,
        profile_picture: true,
        country_location: true,
        city_location: true,
        top_candidate: true,
        skills: true,
        note_for_client: true,
    });

    const [profilePicture, setProfilePicture] = useState<File | null>(null);
    const [CVFile, setCVFile] = useState<File | null>(null);
    const [CVFileName, setCVFileName] = useState<string>("");

    const [loading, setLoading] = useState(false);

    // **New States for Data Loading and Error Handling**
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [dataError, setDataError] = useState<string | null>(null);

    // Options for English Level
    const englishLevelOptions = [
        { value: "1", label: "1 Basic" },
        { value: "2", label: "2 B1" },
        { value: "3", label: "3 B2" },
        { value: "4", label: "4 C1" },
        { value: "5", label: "5 C2" },
    ];

    useEffect(() => {
        InitForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function InitForm() {
        setIsDataLoading(true); // Start loading
        setDataError(null); // Reset any previous errors

        try {
            const token = await LocalStorageManager.getToken();
            const userResReqReturn = await ComunaAPI.getResReqFields(token);
        
            if (userResReqReturn != null) {
                const newSkillOptions = userResReqReturn.skills.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                }));
                setSkillOptions(newSkillOptions);
            }

            // Fetch contractor data
            if (contractor_id && contractors) {
                const foundContractor = contractors.find(
                    (c: any) => c.id === parseInt(contractor_id)
                );
                if (foundContractor) {
                    // Sanitize contractor data
                    const sanitizedContractor: any = {
                        ...foundContractor,
                        first_name: foundContractor.first_name || "",
                        last_name: foundContractor.last_name || "",
                        title: foundContractor.title || "",
                        email: foundContractor.email || "",
                        phone_number: foundContractor.phone_number || "",
                        phone_country_indicator: foundContractor.phone_country_indicator || "",
                        salary_expectation: foundContractor.salary_expectation || "",
                        english_level: foundContractor.english_level || "",
                        seniority: foundContractor.seniority.toString() || "",
                        website: foundContractor.website || "",
                        linkedin: foundContractor.linkedin || "",
                        country_location: foundContractor.country_location || "",
                        city_location: foundContractor.city_location || "",
                        note_for_client: foundContractor.note_for_client || "",
                        skills: foundContractor.skills || [],
                        // cv and profile_picture can remain as they are (string | File | null)
                    };
                    setContractor(sanitizedContractor);
                    setSelectedSkills(
                        sanitizedContractor.skills.map((s: any) => ({
                            label: s.name,
                            value: s.id,
                        }))
                    );
                    if (sanitizedContractor.cv && typeof sanitizedContractor.cv === "string") {
                        setCVFileName(sanitizedContractor.cv);
                    }
                } else {
                    setDataError("Contractor not found.");
                }
            } else {
                setDataError("Invalid contractor ID or no contractors data available.");
            }
        } catch (error) {
            console.error("InitForm Error:", error);
            setDataError("Failed to load contractor data.");
        } finally {
            setIsDataLoading(false); // Loading finished
        }
    }    

    // Function to get badge image based on seniority
    const getBadgeImage = (seniority: string): string | null => {
        switch (seniority) {
            case "1":
                return juniorBadge;
            case "2":
                return midBadge;
            case "3":
                return seniorBadge;
            case "4":
                return leadBadge;
            default:
                return null; // No badge for unknown seniority
        }
    };

    function updateContractorField(field: keyof any, value: any) {
        if (!contractor) return;
        setContractor({
            ...contractor,
            [field]: value,
        });
    }

    function validateForm() {
        if (loading) return;

        if (!contractor) {
            alert("Contractor data is missing.");
            return;
        }

        const newValidations: { [key: string]: boolean } = {
            first_name: contractor.first_name.trim() !== "",
            last_name: contractor.last_name.trim() !== "",
            title: contractor.title.trim() !== "",
            email: contractor.email.trim() !== "",
			phone_number: contractor.phone_country_indicator !== "" ? contractor.phone_number !== "" : true, //contractor.phone_number !== "",
			phone_country_indicator: contractor.phone_number !== "" ? contractor.phone_country_indicator !== "": true,
            salary_expectation: contractor.salary_expectation !== "",
			english_level: contractor.english_level !== "",
			seniority: contractor.seniority !== "",
			website: true,
			linkedin: true,
			cv: true,
			profile_picture: contractor.profile_picture !== "",
            country_location: true, // You can add validation if needed
            city_location: true, // You can add validation if needed
            top_candidate: typeof contractor.top_candidate === "boolean",
			skills: true, // contractor.skills !== "",
            note_for_client: contractor.note_for_client.trim() !== "",
        };
        setValidations(newValidations);

        // Check if all validations are true
        const allValid = Object.values(newValidations).every((v) => v);
        if (allValid) {
            SubmitForm();
        } else {
            alert("Please fill all the required fields correctly.");
        }
    }

    async function SubmitForm() {
        if (!contractor) return;
        setLoading(true);
        const token = await LocalStorageManager.getToken();

        try {
            // Prepare contractor data
            const contractorData = {
                ...contractor,
                skills: contractor.skills.map((skill: any) => skill.id), // Ensure skills are an array of IDs
            };

            console.log("Contractor data", contractorData);
            const response = await ComunaStaffAPI.UpdateContractor(token, contractorData, contractor_id);
            
            if (response) {
                alert("Contractor profile updated successfully.");
                window.location.reload();
                navigate(`/contractors/${response.id}`);
            } else {
                alert("There was an error updating the contractor profile.");
            }
        } catch (error) {
            console.error("Update Error:", error);
            alert("An unexpected error occurred.");
        } finally {
            setLoading(false);
        }
    }

    const customFilterOption = (option: any, searchText: any) => {
        if (!searchText) {
            return true; // Show all options if search text is empty
        }

        const normalizeText = (text: any) =>
            text.toLowerCase().replace(/\s+/g, "");
        const normalizedSearchText = normalizeText(searchText);
        const normalizedOptionLabel = normalizeText(option.label);

        return normalizedOptionLabel.includes(normalizedSearchText);
    };

    // **Update Conditional Rendering Based on Loading and Error States**
    if (isDataLoading) {
        return (
            <div className="comuna-popup">
                <div
                    className="comuna-card padding flex justify-center items-center"
                    style={{
                        overflow: "initial",
                        width: "55%",
                        maxWidth: 1500,
                        minWidth: 800,
                        height: "100vh",
                    }}
                >
                    <Loader />
                </div>
            </div>
        );
    }

    if (dataError) {
        return (
            <div className="comuna-popup">
                <div
                    className="comuna-card padding flex flex-col justify-center items-center"
                    style={{
                        overflow: "initial",
                        width: "55%",
                        maxWidth: 1500,
                        minWidth: 800,
                        height: "100vh",
                        textAlign: "center",
                    }}
                >
                    <h2 className="text-2xl font-bold mb-4">Error</h2>
                    <p className="mb-6">{dataError}</p>
                    <button
                        className="comuna-primary-button"
                        onClick={() => navigate(-1)}
                    >
                        Go Back
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div className="comuna-popup">
            <div
                className="comuna-card padding relative"
                style={{
                    overflow: "initial",
                    width: "55%",
                    maxWidth: 1500,
                    minWidth: 800,
                }}
            >
                <Link
                    to={`/contractors/${contractor_id}`}
                    style={{ position: "absolute", top: 20, right: 20 }}
                >
                    <img src={closeIcon} style={{ width: 24, height: "auto" }} alt="Close" />
                </Link>
                <h1 className="font-bold text-3xl text-left mb-6">Edit Contractor Profile</h1>

                <div className="flex flex-row justify-between items-center bg-purple-light mb-3 rounded-lg p-4">
                    {/* Image preview and Picker */}
                    <div
                        className="flex flex-col bg-white rounded-full items-center"
                        style={{
                            width: 120,
                            height: 120,
                        }}
                    >
                        {contractor.profile_picture ? (
                            <div className="bg-white">
                                {typeof contractor.profile_picture === "string" ? (
                                    <img
                                        style={{
                                            width: 120,
                                            height: 120,
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                        }}
                                        src={contractor.profile_picture}
                                        alt={`Profile of ${contractor.first_name} ${contractor.last_name}`}
                                    />
                                ) : (
                                    <img
                                        style={{
                                            width: 120,
                                            height: 120,
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                        }}
                                        src={URL.createObjectURL(contractor.profile_picture)}
                                        alt="Profile Preview"
                                    />
                                )}
                            </div>
                        ) : (
                            <img
                                className="muna-icon-big-white xl bg-white"
                                style={{
                                    width: 70,
                                    height: 70,
                                    marginTop: 10,
                                    marginBottom: -10,
                                }}
                                src={addImageIcon}
                                alt="Add Profile Picture"
                            />
                        )}
                        <input
                            id="image_input"
                            type="file"
                            name="profilePicture"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(event) => {
                                if (event.target.files && event.target.files[0]) {
                                    setProfilePicture(event.target.files[0]);
                                    updateContractorField("profile_picture", event.target.files[0]);
                                } else {
                                    // Ensure value is not null
                                    updateContractorField("profile_picture", "");
                                }
                            }}
                        />
                        <button
                            className="comuna-white-button xs mx-auto mt-2 text-xs"
                            style={{
                                height: 30,
                                marginTop: -10,
                                marginLeft: -5,
                                width: 130,
                                backgroundColor: "transparent",
                            }}
                            onClick={() => {
                                document.getElementById("image_input")?.click();
                            }}
                        >
                            {"Change Photo"}
                        </button>
                    </div>
                    {/* Contractor details preview */}
                    <div className="flex flex-col justify-center ml-4 flex-1">
                        <span className="font-bold text-xl">
                            {contractor.first_name} {contractor.last_name}
                        </span>
                        <span className="text-gray-2">{contractor.title}</span>
                        <div className="flex flex-row mt-2">
                            {contractor.top_candidate && <Tag tag="top-candidate" />}
                            {getBadgeImage(contractor.seniority) ? (
                                <img
                                    src={getBadgeImage(contractor.seniority)!} // Non-null assertion since we've checked
                                    alt={`${contractor.seniority} Badge`}
                                    style={{ height: 24, marginRight: 12 }}
                                />
                            ) : (
                                <span className="comuna-input-label">Unknown Seniority</span> // Fallback text
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col justify-center">
                        {CVFileName !== "" && (
                            <div className="text-center">
                                <button
                                    className="comuna-secondary-button font-medium color-gray-3 ml-4"
                                    onClick={() => {
                                        window.open(CVFileName);
                                    }}
                                >
                                    View CV
                                </button>
                            </div>
                        )}

                        {contractor.cv && (
                            <div className="text-center">
                                <button
                                    className="font-medium color-gray-3 ml-4"
                                    onClick={() => {
                                        updateContractorField("cv", null);
                                        setCVFileName("");
                                    }}
                                >
                                    Remove CV
                                </button>
                            </div>
                        )}
                        <input
                            id="file_input"
                            type="file"
                            name="cv"
                            style={{ display: "none" }}
                            accept=".pdf,.doc,.docx"
                            onChange={(event) => {
                                if (event.target.files && event.target.files[0]) {
                                    setCVFile(event.target.files[0]);
                                    updateContractorField("cv", event.target.files[0]);
                                    setCVFileName(event.target.files[0].name);
                                } else {
                                    // Ensure value is not null
                                    updateContractorField("cv", "");
                                    setCVFileName("");
                                }
                            }}
                        />
                        <button
                            className="comuna-secondary-button xs mx-auto mt-2"
                            onClick={() => {
                                document.getElementById("file_input")?.click();
                            }}
                        >
                            {contractor.cv ? "Change CV" : "Upload CV"}
                        </button>
                    </div>
                </div>

                {/* Form Fields */}
                <div className="flex flex-col">
                    {/* Name and Title */}
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full">
                            <span className="comuna-input-label">First Name</span>
                            <input
                                value={contractor.first_name || ""}
                                onChange={(e) => updateContractorField("first_name", e.target.value)}
                                className={"comuna-input " + (validations.first_name ? "" : "comuna-error")}
                                type="text"
                                placeholder="John"
                            />
                            {!validations.first_name && (
                                <p className="text-red-400">*First name is required</p>
                            )}
                        </div>
                        <div className="flex flex-col w-full mx-4">
                            <span className="comuna-input-label">Last Name</span>
                            <input
                                value={contractor.last_name || ""}
                                onChange={(e) => updateContractorField("last_name", e.target.value)}
                                className={"comuna-input " + (validations.last_name ? "" : "comuna-error")}
                                type="text"
                                placeholder="Doe"
                            />
                            {!validations.last_name && (
                                <p className="text-red-400">*Last name is required</p>
                            )}
                        </div>
                        <div className="flex flex-col w-full">
                            <span className="comuna-input-label">Title</span>
                            <input
                                value={contractor.title || ""}
                                onChange={(e) => updateContractorField("title", e.target.value)}
                                className={"comuna-input " + (validations.title ? "" : "comuna-error")}
                                type="text"
                                placeholder="Software Engineer"
                            />
                            {!validations.title && (
                                <p className="text-red-400">*Title is required</p>
                            )}
                        </div>
                    </div>

                    {/* Contact Information */}
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full">
                            <span className="comuna-input-label">Email</span>
                            <input
                                value={contractor.email || ""}
                                onChange={(e) => updateContractorField("email", e.target.value)}
                                className={"comuna-input " + (validations.email ? "" : "comuna-error")}
                                type="email"
                                placeholder="john.doe@example.com"
                            />
                            {!validations.email && (
                                <p className="text-red-400">*Valid email is required</p>
                            )}
                        </div>

                        <div className="flex flex-col w-full ml-4">
                            <span className="comuna-input-label">Country Indicator</span>
                            <CountryCodeInput
                                initialValue={contractor.phone_country_indicator || ""}
                                error={!validations.phone_country_indicator}
                                onChange={(v) => {
                                    updateContractorField("phone_country_indicator", v);
                                }}
                                disabled={false}
                            />
                            {!validations.phone_country_indicator && (
                                <p className="text-red-400">*Country indicator is required</p>
                            )}
                        </div>

                        <div className="flex flex-col w-full ml-4">
                            <span className="comuna-input-label">Phone Number</span>
                            <input
                                value={contractor.phone_number || ""}
                                onChange={(e) => updateContractorField("phone_number", e.target.value)}
                                className={"comuna-input " + (validations.phone_number ? "" : "comuna-error")}
                                type="text"
                                placeholder="300 000 00 00"
                            />
                            {!validations.phone_number && (
                                <p className="text-red-400">*Phone number is required</p>
                            )}
                        </div>
                    </div>

                    {/* Salary and English Level */}
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full">
                            <span className="comuna-input-label">Salary Expectation</span>
                            <input
                                type="number"
                                value={contractor.salary_expectation || ""}
                                onChange={(e) => updateContractorField("salary_expectation", e.target.value)}
                                className={
                                    "comuna-input " +
                                    (validations.salary_expectation ? "" : "comuna-error")
                                }
                                placeholder="10000"
                                min={10000}
                            />
                            {!validations.salary_expectation && (
                                <p className="text-red-400">*Salary expectation is required</p>
                            )}
                        </div>

                        <div className="flex flex-col w-full ml-4">
                            <span className="comuna-input-label">English Level</span>
                            <Select
                                className="comuna-select bg-white"
                                classNames={{
                                    menuList: () => "bg-white",
                                }}
                                value={englishLevelOptions.find(
                                    (x) => x.value === contractor.english_level.toString()
                                )}
                                options={englishLevelOptions}
                                onChange={(e) => {
                                    updateContractorField("english_level", e?.value || "");
                                }}
                                isDisabled={false}
                            />
                            {!validations.english_level && (
                                <p className="text-red-400">*English level is required</p>
                            )}
                        </div>
                    </div>

                    {/* Seniority */}
                    <div className="flex flex-col w-full">
                        <span className="comuna-input-label">Seniority</span>
                        <div className="flex flex-row">
                            <button
                                onClick={() => updateContractorField("seniority", "1")}
                                className={
                                    contractor.seniority === "1"
                                        ? "mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-dark mr-4"
                                        : "mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-light mr-4"
                                }
                            >
                                <div className="flex flex-row w-full justify-between items-center">
                                    <img src={juniorBadge} alt="Junior Badge" style={{ height: 24, marginRight: 12 }} />
                                    <input
                                        type="radio"
                                        name="seniority"
                                        value="1"
                                        checked={contractor.seniority === "1"}
                                        onChange={() => updateContractorField("seniority", "1")}
                                    />
                                </div>
                                <b>Junior</b>
                                <b className="font-normal text-xs">1-3 years of experience</b>
                            </button>
                            <button
                                onClick={() => updateContractorField("seniority", "2")}
                                className={
                                    contractor.seniority === "2"
                                        ? "mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-dark mr-4"
                                        : "mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-light mr-4"
                                }
                            >
                                <div className="flex flex-row w-full justify-between items-center">
                                    <img src={midBadge} alt="Mid Badge" style={{ height: 24, marginRight: 12 }} />
                                    <input
                                        type="radio"
                                        name="seniority"
                                        value="2"
                                        checked={contractor.seniority === "2"}
                                        onChange={() => updateContractorField("seniority", "2")}
                                    />
                                </div>
                                <b>Mid-level</b>
                                <b className="font-normal text-xs">3-6 years of experience</b>
                            </button>
                            <button
                                onClick={() => updateContractorField("seniority", "3")}
                                className={
                                    contractor.seniority === "3"
                                        ? "mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-dark mr-4"
                                        : "mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-light mr-4"
                                }
                            >
                                <div className="flex flex-row w-full justify-between items-center">
                                    <img src={seniorBadge} alt="Senior Badge" style={{ height: 24, marginRight: 12 }} />
                                    <input
                                        type="radio"
                                        name="seniority"
                                        value="3"
                                        checked={contractor.seniority === "3"}
                                        onChange={() => updateContractorField("seniority", "3")}
                                    />
                                </div>
                                <b>Senior</b>
                                <b className="font-normal text-xs">6-10 years of experience</b>
                            </button>
                            <button
                                onClick={() => updateContractorField("seniority", "4")}
                                className={
                                    contractor.seniority === "4"
                                        ? "mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-dark"
                                        : "mb-2 rounded-lg p-4 w-full flex flex-col items-start bg-purple-light"
                                }
                            >
                                <div className="flex flex-row w-full justify-between items-center">
                                    <img src={leadBadge} alt="Lead Badge" style={{ height: 24, marginRight: 12 }} />
                                    <input
                                        type="radio"
                                        name="seniority"
                                        value="4"
                                        checked={contractor.seniority === "4"}
                                        onChange={() => updateContractorField("seniority", "4")}
                                    />
                                </div>
                                <b>Lead</b>
                                <b className="font-normal text-xs">10+ years of experience</b>
                            </button>
                        </div>
                        {!validations.seniority && (
                            <p className="text-red-400">*Seniority level is required</p>
                        )}
                    </div>

                    {/* Website and LinkedIn */}
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full">
                            <span className="comuna-input-label">Website</span>
                            <input
                                value={contractor.website || ""}
                                onChange={(e) => updateContractorField("website", e.target.value)}
                                className={"comuna-input " + (validations.website ? "" : "comuna-error")}
                                type="url"
                                placeholder="https://example.com"
                            />
                            {!validations.website && (
                                <p className="text-red-400">*Valid website URL is required</p>
                            )}
                        </div>
                        <div className="flex flex-col w-full ml-4">
                            <span className="comuna-input-label">LinkedIn Profile</span>
                            <input
                                value={contractor.linkedin || ""}
                                onChange={(e) => updateContractorField("linkedin", e.target.value)}
                                className={"comuna-input " + (validations.linkedin ? "" : "comuna-error")}
                                type="url"
                                placeholder="https://linkedin.com/in/username"
                            />
                            {!validations.linkedin && (
                                <p className="text-red-400">*Valid LinkedIn URL is required</p>
                            )}
                        </div>
                    </div>

                    {/* Location */}
                    <div className="flex flex-row">
                        <div className="flex flex-col w-full">
                            <span className="comuna-input-label">Country</span>
                            <CountryInput
                                initialValue={contractor.country_location || ""}
                                error={!validations.country_location}
                                onChange={(value) => {
                                    updateContractorField("country_location", value);
                                }}
                                disabled={false}
                            />
                            {!validations.country_location && (
                                <p className="text-red-400">*Country is required</p>
                            )}
                        </div>
                        <div className="flex flex-col w-full ml-4">
                            <span className="comuna-input-label">City</span>
                            <input
                                value={contractor.city_location || ""}
                                onChange={(e) => updateContractorField("city_location", e.target.value)}
                                className={"comuna-input " + (validations.city_location ? "" : "comuna-error")}
                                type="text"
                                placeholder="City Name"
                            />
                            {!validations.city_location && (
                                <p className="text-red-400">*City is required</p>
                            )}
                        </div>
                    </div>

                    {/* Top Candidate */}
                    <span className="comuna-input-label">Is it a top candidate?</span>
                    <div className="flex flex-row mb-4">
                        <div className="flex flex-row comuna-input mr-4">
                            <input
                                type="radio"
                                name="top_candidate"
                                value="yes"
                                checked={contractor.top_candidate === true}
                                onChange={() => updateContractorField("top_candidate", true)}
                            />
                            <label className="ml-2">Yes</label>
                        </div>
                        <div className="flex flex-row comuna-input">
                            <input
                                type="radio"
                                name="top_candidate"
                                value="no"
                                checked={contractor.top_candidate === false}
                                onChange={() => updateContractorField("top_candidate", false)}
                            />
                            <label className="ml-2">No</label>
                        </div>
                    </div>
                    {!validations.top_candidate && (
                        <p className="text-red-400">*Please specify if it's a top candidate</p>
                    )}

                    {/* Skills */}
                    <span className="comuna-input-label">Skills</span>
                    <Select
                        isDisabled={false}
                        className={validations.skills ? "" : "comuna-error"}
                        options={skillOptions}
                        isMulti
                        value={selectedSkills}
                        filterOption={customFilterOption}
                        onChange={(data) => {
                            const selected = data.map((item: any) => ({
                                label: item.label,
                                value: item.value,
                            }));
                            setSelectedSkills(selected);
                            // Update skills as an array of Skill objects
                            const updatedSkills: any[] = selected.map((s) => ({
                                id: s.value,
                                name: s.label,
                            }));
                            updateContractorField("skills", updatedSkills);
                        }}
                    />
                    {!validations.skills && (
                        <p className="text-red-400">*At least one skill is required</p>
                    )}

                    {/* Notes */}
                    <span className="comuna-input-label">Notes for the Client</span>
                    <textarea
                        value={contractor.note_for_client || ""}
                        onChange={(e) => updateContractorField("note_for_client", e.target.value)}
                        className={
                            "comuna-input " +
                            (validations.note_for_client ? "" : "comuna-error")
                        }
                        placeholder="Notes for the client"
                        rows={4}
                    />
                    {!validations.note_for_client && (
                        <p className="text-red-400">*Notes are required</p>
                    )}

                    {/* Action Buttons */}
                    <div className="flex flex-row mt-4">
                        <button
                            className="comuna-secondary-button mr-4"
                            onClick={() => navigate(-1)}
                        >
                            Cancel
                        </button>
                        <button
                            className="comuna-primary-button"
                            onClick={validateForm}
                            disabled={loading}
                        >
                            {loading ? <Loader /> : "Save Changes"}
                        </button>
                        {contractor_id && (
                            <a
                                className="comuna-special-button ml-4"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`${ADMIN_URL}core/contractor/${contractor_id}/change/`}
                            >
                                Edit in Django
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContractorEditForm;