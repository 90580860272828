// ContractorProfileTab.tsx
import React from "react";
import { useParams, useRouteLoaderData } from "react-router-dom";
import ContractorInfo from "./ContractorInfo";

interface ContractorProfileTabProps {
    contractor: any;
}

const ContractorProfileTab: React.FC<ContractorProfileTabProps> = ({ contractor }) => {
    const { contractor_id } = useParams<any>();
    const editLink = `/contractors/${contractor_id}/edit`;

    return (
        <div className="pt-6">
            <ContractorInfo contractor={contractor} editLink={editLink} />
        </div>
    );
};

export default ContractorProfileTab;    