import { useContext, useEffect, useRef, useState } from "react";
import { ComunaAPI } from "../../managers/ComunaAPI";
import { formatTimestamp } from "../../utils/formaters";
import {
	primaryButtonClassName,
	secondaryButtonClassName,
	cancelButtonClassName,
	disabledButtonClassName,
} from "../../utils/commonClassNames";
// import { UserContext } from "../Providers/UserContext";
import { LocalStorageManager } from "../../managers/LocalStorageManager";
import { useLoaderData } from "react-router-dom";
import { ProposalFromServer } from "../../types";
import { UserContext } from "../../Providers/UserContext";

interface MessageComponentProps {
	application: any;
	updatingApplication: any;
	upToDate: () => void;
	reloadApplication: () => void;
}

const MessagesComponent: React.FC<MessageComponentProps> = ({
	application,
	updatingApplication,
	upToDate,
	reloadApplication,
}) => {
	const token = LocalStorageManager.getToken();
	const { user }: any = useContext(UserContext);
	const noteScrollContainer = useRef<HTMLDivElement>(null);
	const [notes, setNotes] = useState([]);

	const [newMessage, setNewMessage] = useState<string>();

	const [updatedProposal, setUpdatedProposal] = useState<ProposalFromServer>();

	const [loopCount, setLoopCount] = useState<number>(0);

	useEffect(() => {
		if (application !== undefined) {
			//append notes and notes_proposal
			var newNotes = application.notes;
			if (application.notes_proposal !== undefined) {
				newNotes = newNotes.concat(application.notes_proposal);
			}
			console.log("updated component application:", application);
			setNotes(newNotes); // append notes and notes_proposal
			// }
			// if (
			// 	updatingProposal !== undefined &&
			// 	updatingProposal.notes.length > proposal.notes.length
			// ) {
			// 	setNotes(updatingProposal.notes);
			upToDate();
			ScrollNotesToTheBottom();
		}
	}, [application, updatingApplication]);

	useEffect(() => {
		// updates notifications every 3 seconds
		var newLoopValue = 0;
		if (loopCount < 3) {
			newLoopValue = loopCount + 1;
		}

		//UPDATE
		reloadApplication();

		const timer = window.setTimeout(() => {
			setLoopCount(newLoopValue);
		}, 3000);
		return () => clearTimeout(timer);
	}, [loopCount]);

	useEffect(() => {
		if (updatedProposal !== undefined) {
			setNotes(updatedProposal.notes);
		}
	}, [updatedProposal]);

	const ScrollNotesToTheBottom = () => {
		window.setTimeout(() => {
			if (noteScrollContainer.current) {
				noteScrollContainer.current.scrollTop =
					noteScrollContainer.current.scrollHeight;
			}
		}, 250);
	};

	const SendMessage = async () => {
		try {
			if (newMessage !== "") {
				var appUpdate = {
					update: "note",
					content: newMessage,
				};
				console.log(
					"application: " + application.id + " Update data: ",
					appUpdate
				);

				await ComunaAPI.StaffUpdateApplication(
					token,
					application.id,
					appUpdate
				);
				setNewMessage("");

				reloadApplication();
				// const updatedPoposal = await ComunaAPI.GetUserProposals(token);
				// setNotes(updatedPoposal[0].notes);

				ScrollNotesToTheBottom();
			}
		} catch (error) {
			alert("There was an error sending your message, please try again later");
		} finally {
			//ComunaAPI.getA
		}
	};

	return (
		<div className="flex flex-col w-full h-full justify-start bg-white rounded-2xl p-2">
			<div className="flex flex-col h-full p-2 bg-white rounded-xl">
				<label className="text-gray-500 font-semibold text-lg pl-1 pb-2">
					Leave a comment for TA
				</label>
				<div
					ref={noteScrollContainer}
					className="bg-white border-2 rounded-xl w-full h-full p-4 overflow-y-auto">
					{notes !== undefined &&
						notes.map((note: any, i: number) => {
							return (
								<div
									key={i}
									className={
										" flex flex-col mb-4 w-full " +
										(note.creator === user.username
											? " items-end"
											: " items-start")
									}>
									<div className="flex flex-row">
										<span className="font-bold">
											{note.creator_name +
												(note.creator_profile === "comuna_staff"
													? " from MUNA"
													: " ")}
										</span>
										<span className="text-xs ml-2 mt-1 text-gray-600">
											{formatTimestamp(note.date_created)}
										</span>
									</div>
									<p
										className={
											"  p-3 mt-2 " +
											(note.creator === user.username
												? "rounded-l-xl rounded-br-xl text-white bg-comuna-purple"
												: "rounded-r-xl rounded-bl-xl text-black bg-gray-200")
										}>
										{note.content}
									</p>
								</div>
							);
						})}
				</div>
				<div className="flex flex-row mt-5">
					<input
						value={newMessage}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setNewMessage(e.target.value)
						}
						className="w-full  bg-gray-100 rounded-xl mr-8 p-4"
						placeholder="Send a message to the candidate and the MUNA Staff"
						type="text"
					/>
					<button
						onClick={SendMessage}
						className={primaryButtonClassName + " w-64"}>
						Send Message
					</button>
				</div>
			</div>
		</div>
	);
};

export default MessagesComponent;
