import { NavLink, useLoaderData, Outlet } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import Select from "react-select";

import { NewMessages } from "../components/NewMessagesList";

import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";

import RoleApplicationManager from "../components/RoleApplicationManager";

import ConfirmPopup from "../components/ConfirmPopup";
import { ComunaAPI } from "../managers/ComunaAPI";
import { ADMIN_URL } from "../config";


const arrow_left = require("../img/icons/arrow-left.png");
const office_icon = require("../img/icons/office.png");
const multi_user_icon = require("../img/icons/multi-users-icon.png");

const plus_icon = require("../img/icons/plus-dark.png");
const file_search_icon = require("../img/icons/file-search-dark.png");
const interview_icon = require("../img/icons/message-question-circle.png");
const list_icon = require("../img/icons/list.png");
const archive_icon = require("../img/icons/archive-dark.png");
const archiveIconGray = require("../img/icons/archive_gray.png");
const web_icon = require("../img/icons/web-icon.png");


const tabStyle = "px-6 py-3 font-medium border-b-4 border-gray-1 flex-1 black-text font-bold relative"
const tabSelectedStyle = "px-6 py-3 font-bold comuna-purple border-b-4 border-comuna-purple flex-1 relative"

export async function loader({ params }: any){
	var token = await LocalStorageManager.getToken();
    const resource_request_source = await ComunaStaffAPI.GetResourceRequestDetail(token, params.request_id);
    let notifications = await ComunaAPI.getStaffNotifications(token);
    notifications = notifications.filter((notification:any)=> notification.cleared === false);
    const applicationIds = resource_request_source.applications.map((application:any)=> application.id);
    const filteredNotifications = notifications.filter((notification:any)=> applicationIds.includes(notification.target));
    notifications = filteredNotifications;
    //filter for notifications related to this request that have cleared 

    return {resource_request_source, notifications};
}

export function RequestDetailViewV1(){
    const [detailWindowWidth, setDetailWindowWidth] = useState(window.innerWidth-450) ;

    //add an onresice event listener with use effect and unsubscribe on return
    useEffect(()=>{
        window.addEventListener("resize", resize);

        return ()=>{
            window.removeEventListener("resize", resize)}
    }, []);

    function resize(){
            setDetailWindowWidth(window.innerWidth-450);
    }

    const {resource_request_source, notifications}:any = useLoaderData();
    const [resource_request, setResourceRequest] = useState<any>(resource_request_source);

    const [tabSelected, setTabSelected] = useState("details");
    const [archiving, setArchiving] = useState(false);
    const [unarchiving, setUnarchiving] = useState(false);

    function GetHiredCount(){
        var count = 0;
        resource_request.applications.forEach((application:any)=>{
            if(application.proposal!=null &&  application.proposal.state===1){
                count++;
            }
        })
        return count;
    }

    async function ArchiveResourceRequest(value:boolean){
        const token = await LocalStorageManager.getToken();
        const resReq = await ComunaStaffAPI.ArchiveResourceRequest(token, resource_request.id, value);
        if(resReq){
            window.location.reload();
        }else{
            alert("Error archiving request");
        }
    }
    
    return (
        <div className="w-full h-full flex flex-col relative">

            {resource_request.archived && 
            <div className="w-full comuna-bg-stripes flex flex-row items-center justify-between pr-8" style={{height:70}}>
                <div className="flex flex-row">
                    <img style={{width:32, marginRight:10, marginLeft:20}} src={archiveIconGray} alt="Archive icon gray" />
                    <b className="color-gray-5 font-medium text-xl">This request is archived.</b>
                </div>
                <button className="comuna-disabled-button sm justify-self-end" onClick={()=>{ setUnarchiving(true); }}>Unarchive</button>
            </div>}

        <div  className="w-full h-full flex flex-row justify-start relative">
            
            {/** SIDE BAR  */}
            <div className="bg-white h-full pt-6" style={{borderLeft:"2px solid #EFEFEE", overflowY: "auto", minWidth: 364}}>
                            
                {/** Title and Back Button  */}
                <div className="flex flex-row items-center px-8">

                    <NavLink to="/requests" className="mr-4">
                        <img src={arrow_left} alt="" style={{width:24, height:24}} />
                    </NavLink>

                    {resource_request.organization.picture ? 
                    <img src={resource_request.organization.picture} alt="" className="rounded-xl overflow-hidden" style={{width:56, height:56}} />
                    :
                    <div className="rounded-xl flex items-center justify-center bg-gray-3" style={{width:56, height:56}}>
                        <img src={office_icon} alt="" style={{width:24, height:24}} />
                    </div>
                    }

                    <div className="ml-4" style={{maxWidth:180}}>
                        <h1 className="font-bold text-l leading-4">{resource_request.role_name}</h1>
                        <p className="font-medium text-black text-sm">{resource_request.organization.name}</p>
                    </div>

                </div>

                 {/** TABS ROW */}
                 <div className="flex flex-row w-full mt-4">
                    <button onClick={()=>setTabSelected("details")} className={(tabSelected==="details"?tabSelectedStyle:tabStyle)}>Details</button>
                    <button onClick={()=>setTabSelected("messages")}  className={tabSelected==="messages"?tabSelectedStyle:tabStyle}>
                       <span>Messages</span> 
                       {notifications.length>0 && <span style={{position:"absolute", top: 12, right: 10}} className="bg-comuna-purple text-white font-bold w-4 h-4 p-3 rounded-full flex justify-center items-center">{notifications.length}</span>}
                    </button>
                </div>

                {/** GENERAL DETAILS */}
                {tabSelected === "details" &&
                <div className="p-4" style={{borderBottom:"2px solid #EFEFEE"}} >
                    <p className="bg-gray-1 rounded-lg py-2 px-4 text-black">
                        <b>{resource_request.comuna_agent?resource_request.comuna_agent.name:"No Agent Asigned"}</b>
                        <br/>
                        <span>TA Agent</span>
                    </p>
                    <div className="flex flex-row mt-3 items-center">
                        <p className="flex flex-row bg-gray-1 p-4 rounded-lg">
                            <img src={multi_user_icon} alt="" style={{width:24, height:24}} className="mr-2" />
                            <span className="font-bold text-black text-lg">{resource_request.applications.length}</span>
                        </p>
                        <p className="flex flex-row items-center bg-gray-1 p-4 rounded-lg ml-4 flex-1">
                            <span className="font-bold text-black text-lg mr-4"> <span style={{color:"#298C69"}}>{GetHiredCount()}</span>/{resource_request.quantity}</span>
                            <span className="active-badge">Active</span>
                        </p>
                    </div>
                </div>
                }

                {/** ACTION BUTTONS */}
                {tabSelected === "details" &&
                <div className="p-4" >
                   <NavLink to="new" className="comuna-action-button">
                        <img src={plus_icon} alt="" />
                        <span>Add application</span>
                    </NavLink>

                    <NavLink to="detail" className="comuna-action-button">
                        <img src={file_search_icon} alt="" />
                        <span>Request detail</span>
                    </NavLink>

                    
                    <NavLink to="questions" className="comuna-action-button">
                        <img src={interview_icon} alt="" />
                        <span>Interview questions</span>
                    </NavLink>

                    {/*<NavLink to="search" className="comuna-action-button">
                        <img src={interview_icon} alt="" />
                        <span>Search Candidates</span>
                    </NavLink> */}

                    {/*<button className="comuna-action-button">
                        <img src={list_icon} alt="" />
                        <span>Activity</span>
                    </button> */}

                    <button className="comuna-action-button" onClick={()=>{ setArchiving(true); }}>
                        <img src={archive_icon} alt="" />
                        <span>Archive request</span>
                    </button>

                    <a className="comuna-action-button" href={ADMIN_URL+"core/resourcerequest/"+resource_request.id+"/change/"} target="blank">
                        <img src={web_icon} alt="" />
                        <span>Edit in Django</span>
                    </a>

                </div>}

                {/** MESSAGES */}
                {tabSelected === "messages" && <NewMessages resource_request={resource_request}/>}
            </div>

            {/** MAIN CONTENT */}
            <div className="flex-1 overflow-x-auto" style={{maxWidth: detailWindowWidth}}>
                <RoleApplicationManager resource_request={resource_request} set_resource_request={setResourceRequest}/>
            </div>

        </div>
        

        {archiving && 
            <ConfirmPopup 
                title="Archive request?"
                message="Are you sure you want archive this resource request?"
                action="Archive this Request"
                onConfirm={()=>{ ArchiveResourceRequest(true)}}
                onCancel={()=>{ setArchiving(false); }}
            />}

        {unarchiving && 
            <ConfirmPopup 
                title="Unarchive request?"
                message="Are you sure you want unarchive this resource request?"
                action="Unarchive this Request"
                onConfirm={()=>{ ArchiveResourceRequest(false)}}
                onCancel={()=>{ setUnarchiving(false); }}
            />}
        <Outlet/>
        </div>
    );

}