// ContractorDetailView.tsx
import React, { useEffect, useState } from "react";
import { useRouteLoaderData, useParams, Outlet } from "react-router-dom";
import { ComunaSidePanel } from "../components/ComunaSidePanel";

import ContractorProfileTab from "../components/ContractorProfileTab";
import { Loader } from "../components/loader";

export default function ContractorDetailView() {
    const { contractor_id } = useParams<{ contractor_id: string }>();
    const { contractors }: any = useRouteLoaderData("contractors_list");

    const [contractor, setContractor] = useState<any>(null);

    useEffect(() => {
        if (contractors && contractor_id) {
            const foundContractor = contractors.find((c: any) => c.id === parseInt(contractor_id));
            setContractor(foundContractor);
        }
    }, [contractors, contractor_id]);

    const [selectedTab, setSelectedTab] = useState("profile");

    function Tab({ tab, label }: { tab: string; label: string }) {
        return (
            <div
                onClick={() => setSelectedTab(tab)}
                className={`cursor-pointer rounded-t-xl bg-white text-sm font-semibold py-3 px-4 text-center ${
                    selectedTab === tab ? "border-b-4 border-comuna-purple" : ""
                }`}
            >
                {label}
            </div>
        );
    }

    if (!contractor) {
        return <Loader />;
    }

    const editLink = `/contractors/${contractor_id}/edit`;

    return (
        <div>
            <ComunaSidePanel return_to="/contractors">
                {/* Tab Options */}
                <div className="flex flex-row top justify-start w-full h-fit mx-auto border-b-2">
                    <Tab label="Profile" tab="profile" />
                </div>

                {/* Render Tabs */}
                {selectedTab === "profile" && <ContractorProfileTab contractor={contractor} />}
            </ComunaSidePanel>
            <Outlet />
        </div>
    );
}