import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserContext } from "../Providers/UserContext";

const comuna_icon = require("../img/icons/muna-icon.png")
const requests_icon = require("../img/icons/message-plus-square.png");
const contractors_icon = require("../img/icons/contractors.png");
const userIcon = require("../img/icons/profile-icon.png");

function MenuOption({ label, link, icon }:any) {
    return (
        <NavLink
            className={({ isActive }) => (isActive ? "selected comuna-circle-button" : "comuna-circle-button") }
            to={link}>
            <img src={icon} alt="" />
            <span className="bg-black text-white">{label}</span>
        </NavLink>
    );
}

export function StaffMenuBar(){

	const { user, logOut }:any = useContext(UserContext);
	const navigate = useNavigate();

    useEffect(() => {
		if (window.location.pathname === "/") {
			navigate("/requests");
		}
	}, []);

    function onLogOutClick() {
		navigate("/");
		logOut();
	}

    return (
        <div style={{width:80, padding:"18px 20px"}} className="flex flex-col items-center bg-white pt-8">
            <img style={{width:37, height:46}} src={comuna_icon} alt="MUNA icon, C" />

            <nav className="flex flex-col mt-8">
                <MenuOption label="Requests" link="/requests" icon={requests_icon} />
            </nav>

            <nav className="flex flex-col mt-8">
                <MenuOption label="Contractors" link="/contractors" icon={contractors_icon} />
            </nav>

            <div className="p-4 rounded-full bg-purple-light mb-4 comuna-circle-button mt-auto">
            <img
                    style={{
                        width: 24, 
                        height: 24  
                    }}
				    src={userIcon}
				    alt="gray background and the number 120x120 in darker gray letters"
                />

                <span className="flex flex-col comuna-card">
                    <b className="my-0">{user.username}</b>
                    <button onClick={onLogOutClick} style={{marginTop:-10}} className="text-red-500 font-bold text-left ">Logout</button>
                </span>

            </div>

        </div>
    );
}