// ContractorsView.tsx

import { useContext, useState, useEffect, Fragment } from "react";
import { UserContext } from "../Providers/UserContext";
import { LocalStorageManager } from "../managers/LocalStorageManager";
import { ComunaStaffAPI } from "../managers/ComunaStaffAPI";
import { useLoaderData, Link, useNavigate, Outlet } from "react-router-dom";

import Fuse from 'fuse.js'
const searchIcon = require("../img/icons/search-icon.png");

const contractor_icon = require("../img/indivIcon.png");

// Helper function to capitalize the first letter
const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();

export async function loader({ params }: any) {
  const token = await LocalStorageManager.getToken();
  const contractors = await ComunaStaffAPI.GetContractors(token, []);
  const skills = await ComunaStaffAPI.GetContractorFields(token);
  return { contractors, skills };
}

// Updated Fuse.js options to search first_name and last_name separately
const fuseOptions = {
  keys: [
    "first_name",
    "last_name",
    "title",
    "skills.name"
  ],
  includeScore: true
}

export function ContractorsView() {

  const navigate = useNavigate();

  const { user } = useContext<any>(UserContext);
  const { contractors, skills }: any = useLoaderData();
  const [availableContractors, setAvailableContractors] = useState(contractors);
  const [filterContractors, setFilterContractors] = useState(contractors);
  const [allSkills, setAllSkills] = useState<any[]>(skills.skills);
  const [selectedSkills, setSelectedSkills] = useState<any[]>([]);

  const [filter, setFilter] = useState("all"); // new or all
  const [query, setQuery] = useState(""); // search query
  const [skillSearch, setSkillSearch] = useState(""); // Skill search input
  const [showSkillDropdown, setShowSkillDropdown] = useState(false);

  useEffect(() => {
    UpdateContractors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, selectedSkills]);

  useEffect(() => {
    if (query === "") {
      UpdateContractors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  async function UpdateContractors() {
    const token = await LocalStorageManager.getToken();
    const contractorsData = await ComunaStaffAPI.GetContractors(token, selectedSkills.map((s: any) => s.id));
    setAvailableContractors(contractorsData);

    let filteredContractors = contractorsData;

    // Apply text search if query is not empty
    if (query.trim() !== "") {
      const fuse = new Fuse(contractorsData, fuseOptions);
      const searchResults = fuse.search(query);
      filteredContractors = searchResults.map((result: any) => result.item);
    }

    setFilterContractors(filteredContractors);
  }

  function SearchQuery(event: any) {
    if (event.key === 'Enter') {
      UpdateContractors();
    }
  }

  function handleSkillSelection(skill: any) {
    if (!selectedSkills.some((s: any) => s.id === skill.id)) {
      const updatedSkills = [...selectedSkills, skill];
      setSelectedSkills(updatedSkills);
      UpdateContractors();
      setSkillSearch("");
      setShowSkillDropdown(false);
    }
  }

  function removeSkill(skillToRemove: any) {
    const updatedSkills = selectedSkills.filter((skill: any) => skill.id !== skillToRemove.id);
    setSelectedSkills(updatedSkills);
    UpdateContractors();
  }

  function ContractorRow(props: any) {
    if (props.items.length === 0) {
      return (
        <tbody>
          <tr className="bg-gray-100 rounded-xl">
            <td className="text-center py-4" colSpan={3}>No contractors found</td>
          </tr>
        </tbody>
      )
    }

    return (
      <tbody>
        {props.items.map((item: any) => {
          const fullName = `${capitalize(item.first_name)} ${capitalize(item.last_name)}`;
          return (
            <Fragment key={item.id}>
              <tr className="text-black cursor-pointer hover:bg-gray-100"
                style={{ borderBottom: "1px solid #e0e0e0" }}
                onClick={() => { navigate("/contractors/" + item.id) }}>
                <td className="pl-6 py-4">
                  <div className="flex items-center">
                    <img
                      className="w-10 h-10 rounded-lg"
                      src={item.profile_picture || contractor_icon}
                      alt="Profile"
                      onError={(e) => {
                        // Set the default image if loading fails
                        (e.target as HTMLImageElement).src = contractor_icon;
                      }}
                    />
                    <span className="ml-2 font-bold">{fullName}</span>
                  </div>
                </td>
                <td className="py-4">{item.title}</td>
                <td className="py-4">
                  <div className="flex flex-wrap">
                    {item.skills.map((skill: any) => (
                      <span key={skill.id} className="bg-gray-200 text-gray-800 px-2 py-1 rounded-full text-sm mr-2 mb-2">
                        {skill.name}
                      </span>
                    ))}
                  </div>
                </td>
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    )
  }

  // Filtered skills based on search input
  const filteredSkills = allSkills.filter((skill: any) =>
    skill.name.toLowerCase().includes(skillSearch.toLowerCase())
  );

  return (
    <div className="w-full h-full px-6 flex flex-col">
      <div className="header flex justify-between items-start mt-6 mb-4">
        <h1 className="text-3xl font-bold">Contractors</h1>

      </div>

      <div className="comuna-card w-full pt-4" style={{ maxHeight: "86%" }}>

        <div className="flex flex-col border-b-2 pb-4">
          <div className="flex flex-row items-center">
            <span className="color-gray-1 text-right mr-2" style={{ width: 200 }}>Filter by</span>

            {/* Skills Filter */}
            <div className="relative w-1/3">
              <input
                type="text"
                value={skillSearch}
                onChange={(e) => {
                  setSkillSearch(e.target.value);
                  setShowSkillDropdown(true);
                }}
                onFocus={() => setShowSkillDropdown(true)}
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder="Search skills..."
              />
              {showSkillDropdown && (
                <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-60 overflow-y-auto">
                  {filteredSkills.slice(0, 5).map((skill: any) => (
                    <div
                      key={skill.id}
                      onClick={() => handleSkillSelection(skill)}
                      className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                    >
                      {skill.name}
                    </div>
                  ))}
                  {filteredSkills.length > 5 && (
                    <div className="px-4 py-2 text-center text-gray-500">Showing first 5 results</div>
                  )}
                </div>
              )}
            </div>

            {/* Clear Skills Filter */}
            {selectedSkills.length > 0 && (
              <button
                className="ml-2 text-red-500 hover:text-red-700"
                onClick={() => {
                  setSelectedSkills([]);
                  setShowSkillDropdown(false);
                  UpdateContractors();
                }}>
                Clear Skills
              </button>
            )}

            <div className="w-full"></div>

            {/* Search Bar */}
            <div className="relative w-1/3 m-3">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <img src={searchIcon} className="w-5 h-5" alt="Search" />
              </div>
              <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onKeyDown={SearchQuery}
                className="w-full border border-gray-300 rounded-lg pl-10 pr-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-600"
                placeholder="Search by name or title..."
              />
            </div>
          </div>

          {/* Selected Skills */}
          {selectedSkills.length > 0 && (
            <div className="flex flex-wrap ml-5 mt-2">
              {selectedSkills.map((skill: any) => (
                <div key={skill.id} className="bg-purple-600 text-white rounded-full px-4 py-2 m-1 flex items-center">
                  {skill.name}
                  <button onClick={() => removeSkill(skill)} className="ml-2 text-white font-bold">
                    &times;
                  </button>
                </div>
              ))}
            </div>
          )}
        </div>

        <div style={{ height: "75vh", overflowY: "auto" }}>
          <table className="w-full mt-8">
            <thead>
              <tr className="text-black">
                <th className="pl-6 py-2">Contractor</th>
                <th className="text-left py-2">Title</th>
                <th className="text-left py-2">Skills</th>
              </tr>
            </thead>
            <ContractorRow items={filterContractors} />
          </table>
        </div>

      </div>
      <Outlet />
    </div>
  );
}