// ApplicationProfileTab.tsx
import React from "react";
import { useParams, useRouteLoaderData } from "react-router-dom";
import ContractorInfo from "./ContractorInfo";

interface ApplicationProfileTabProps {
    application: any;
    contractor: any;
}

const ApplicationProfileTab: React.FC<ApplicationProfileTabProps> = ({ application, contractor }) => {
    const { application_id } = useParams<any>();
    const { resource_request_source } = useRouteLoaderData("resource_request_detail") as any;

    const editLink = `/requests/${resource_request_source.id}/application/${application_id}/edit`;

    return (
        <div className="pt-6">
            <ContractorInfo contractor={contractor} application={application} editLink={editLink} resource_request_source={resource_request_source}/>
        </div>
    );
};

export default ApplicationProfileTab;