import axios from "axios";
import { BASE_URL } from "../config";

const getCookie = (name) => {
	let cookieValue = null;
	if (document.cookie && document.cookie !== "") {
		const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === name + "=") {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
		return cookieValue;
	}
};

const defaultHeaders = {
	"Content-Type": "application/json",
	"X-CSRFToken": getCookie("csrftoken"),
};


const authenticatedheaders = (token, isFormData = false) => {
	if (isFormData) {
		return {
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};
	}
	return {
		"Content-Type": "application/json",
		Authorization: `Token ${token}`,
		"X-CSRFToken": getCookie("csrftoken"),
	};
};

export class ComunaStaffAPI {
	static async GetOrganizationList(token) {
		const completeUrl = `${BASE_URL}staff/organizations`;
		const response = await axios.get(completeUrl, {
			headers: authenticatedheaders(token),
		});
		return response.data;
	}

	static async GetResourceRequests(token, filter, archived = false) {
		try {
			const response = await axios.get(`${BASE_URL}staff/resource_request?filter=${filter}` + (archived ? '&archived=true' : ''), {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	static async GetResourceRequestDetail(token, id) {
		try {
			const response = await axios.get(`${BASE_URL}staff/resource_request/${id}`, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	static async UpdateResourceRequest(token, id, roleInfo) {
		try {
			const newResReqInfo = {
				role_name: roleInfo.roleName,
				quantity: roleInfo.roleQuantity,
				modality: roleInfo.roleModality,
				seniority: roleInfo.roleSeniority,
				skills: roleInfo.roleSkills.map((s) => s.value),
				labels: roleInfo.roleLabels.map((s) => s.value),
				position_overview: roleInfo.roleDescription,
				benefits: roleInfo.benefits,
				start_date: roleInfo.roleStartDate,
				location: roleInfo.roleLocation,
				additional_details: roleInfo.roleDetails,
				interview_weights: roleInfo.interviewWeights,
			};

			const response = await axios.post(`${BASE_URL}staff/resource_request/${id}`, newResReqInfo, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			return false;
		}
	}

	static async UpdateResourceRequestLabels(token, request_id, labels) {
		try {
			const completeUrl = `${BASE_URL}staff/resource_request/${request_id}/labels/`;
			const headers = authenticatedheaders(token);
			const data = {
				labels: labels
			};

			const response = await axios.post(completeUrl, data, {
				headers: headers
			});

			return response.data;
		} catch (error) {
			console.log(error);
			return false;
		}
	}

	static async ArchiveResourceRequest(token, id, value) {
		try {
			const response = await axios.post(`${BASE_URL}staff/resource_request/archive/${id}`, { "archived": value }, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			return false;
		}
	}

	static async UpdateApplicationState(token, id, state) {

		try {
			const response = await axios.post(`${BASE_URL}staff/application/state/${id}/${state}`, {}, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			return false;
		}
	}

	static async SendMessage(token, application_id, message) {
		try {
			const response = await axios.post(`${BASE_URL}staff/application/message/${application_id}`, message, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			return false;
		}
	}

	static async GetMessages(token, application_id, message) {
		try {
			const response = await axios.get(`${BASE_URL}staff/application/message/${application_id}`, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			return false;
		}
	}

	static async GetComunaAgents(token) {
		try {
			const response = await axios.get(`${BASE_URL}staff/comuna_agents`, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		}
		catch (error) {
			console.log(error);
			return false;
		}
	}

	static async AssignComunaAgent(token, agent_id, resource_request_id) {
		try {
			const response = await axios.post(`${BASE_URL}staff/resource_request/assign/${resource_request_id}/${agent_id}`, {
				agent_id,
				resource_request_id
			}, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		}
		catch (error) {
			console.log(error);
			return false;
		}
	}

	static async CreateApplication(token, resource_request_id, contractor) {
		try {
			const formData = new FormData();
			//turn contractor into form data
			for (const key in contractor) {
				formData.append(key, contractor[key]);
			}

			formData.set("skills", [...contractor.skills])

			if (contractor.profile_picture && typeof contractor.profile_picture === "object") {
				//add a timestamp to the name of the file to prevent overriding existing ones
				const timestamp = new Date().getTime();
				const fileName = contractor.profile_picture.name.split(".")[0] + "-" + timestamp + "." + contractor.profile_picture.name.split(".")[1];
				formData.set("profile_picture", contractor.profile_picture, fileName);
			} else {
				formData.delete("profile_picture")
			}

			if (contractor.cv && typeof contractor.cv === "object") {
				const timestamp = new Date().getTime();
				const fileName = contractor.cv.name.split(".")[0] + "-" + timestamp + "." + contractor.cv.name.split(".")[1];
				formData.set("cv", contractor.cv, fileName);
			} else {
				formData.delete("cv")
			}

			const response = await axios.post(`${BASE_URL}staff/resource_request/${resource_request_id}/application`, formData, {
				headers: authenticatedheaders(token, true),
			});
			if (response.status === 201 || response.status === 200) {
				try {
					return response.data;
				} catch (error) {
					return true;
				}

			}
			return false;
		}
		catch (error) {
			console.log(error);
			return false;
		}
	}

	static async UpdateApplication(token, application_id, application) {
		try {
			console.log("response", application);
			console.log("response", application_id);
			const response = await axios.post(`${BASE_URL}staff/application/${application_id}/edit`, application, {
				headers: authenticatedheaders(token, true),
			});

			if (response.status === 201 || response.status === 200) {
				try {
					return response.data;
				} catch (error) {
					return true;
				}

			}
			return false;
		}
		catch (error) {
			console.log(error);
			return false;
		}
	}

	static async GetClientList(token) {
		try {
			const response = await axios.get(`${BASE_URL}staff/clients`, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		}
		catch (error) {
			console.log(error);
			return false;
		}
	}

	static async CreateResourceRequestForClient(token, roleInfo, benefits) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};

		//turn skills into an array of numbers
		var skills_ = [];
		roleInfo.roleSkills.forEach((skill) => {
			skills_.push(skill.value);
		});

		const newResReqInfo = {
			role_name: roleInfo.roleName,
			quantity: roleInfo.roleQuantity,
			modality: roleInfo.roleModality,
			seniority: roleInfo.roleSeniority,
			skills: skills_,
			position_overview: roleInfo.roleDescription,
			benefits: benefits,
			start_date: roleInfo.roleStartDate,
			location: roleInfo.roleLocation,
			additional_details: roleInfo.roleDetails,
			client_email: roleInfo.clientEmail
		};
		const completeUrl = `${BASE_URL}staff/resource_request`;

		try {
			const response = await axios.post(completeUrl, newResReqInfo, {
				headers: headers,
			});
			if (response.status === 201 || response.status === 200) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}

	static async GenerateInterviewQuestions(token, resource_request_id) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};

		const completeUrl = `${BASE_URL}staff/resource_request/${resource_request_id}/interviewquestions`;

		try {
			const response = await axios.post(completeUrl, { num_questions: 10 }, {
				headers: headers,
			});
			if (response.status === 202) {
				return response.data;
			} else {
				return null;
			}
		} catch (error) {
			console.log("Error generating questions ", error);
			return null;
		}
	}

	static async UpdateInterviewQuestions(token, resource_request_id, question_id, question_value) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};

		const newQuestionInfo = {
			question: question_value
		}

		const completeUrl = `${BASE_URL}staff/resource_request/${resource_request_id}/interviewquestions/${question_id}`;

		try {
			const response = await axios.patch(completeUrl, newQuestionInfo, {
				headers: headers,
			});
			if (response.status === 201 || response.status === 200) {
				return response.data.question;
			} else {
				return null;
			}
		} catch (error) {
			return null;
		}
	}


	static async SendInterviewInvitation(token, application_id, interview_date, call_link) {
		try {
			const response = await axios.post(`${BASE_URL}staff/application/interview/${application_id}`,
				{
					"date": interview_date,
					"link": call_link
				},
				{
					headers: authenticatedheaders(token),
				});
			if (response.status === 200) {
				return "OK";
			} else {
				console.log(response);
				return response.data;
			}
		}
		catch (error) {
			console.log(error);
			return error;
		}
	}

	static async CreateLabel(token, labelName) {
		try {
			const response = await axios.post(`${BASE_URL}staff/labels/`, { "name": labelName }, {
				headers: authenticatedheaders(token),
			});
			return response.status === 201 ? response.data : null;
		} catch (error) {
			console.error(error);
			if (error.response && error.response.data && error.response.data.name && error.response.data.name[0])
				throw new Error("Failed to create label. " + error.response.data.name[0]);
			throw new Error("Failed to create label.");
		}
	}

	static async GetLabels(token) {
		try {
			const response = await axios.get(`${BASE_URL}staff/labels/`, {
				headers: authenticatedheaders(token),
			});
			console.log(response.data);
			return response.status === 200 ? response.data : [];
		} catch (error) {
			console.error(error);
			if (error.response && error.response.data && error.response.data.name && error.response.data.name[0])
				throw new Error("Failed to get all labels. " + error.response.data.name[0]);
			throw new Error("Failed to get all labels.");
		}
	}

	static async UpdateLabel(token, id, newName) {
		try {
			const response = await axios.put(`${BASE_URL}staff/labels/${id}/`, { "name": newName }, {
				headers: authenticatedheaders(token),
			});
			return response.status === 200 ? response.data : null;
		} catch (error) {
			console.error(error);
			if (error.response && error.response.data && error.response.data.name && error.response.data.name[0])
				throw new Error("Failed to update label. " + error.response.data.name[0]);
			throw new Error("Failed to update label.");
		}
	}

	static async DeleteLabel(token, id) {
		try {
			const response = await axios.delete(`${BASE_URL}staff/labels/${id}/`, {
				headers: authenticatedheaders(token),
			});
			return response.status === 200 ? "Deleted" : null;
		} catch (error) {
			console.error(error);
			if (error.response && error.response.data && error.response.data.name && error.response.data.name[0])
				throw new Error("Failed to delete label. " + error.response.data.name[0]);
			throw new Error("Failed to delete label.");
		}
	}

	static async GetSearchResults(token, resourceRequestId) {
		try {
			const response = await axios.get(`${BASE_URL}staff/resource_request/${resourceRequestId}/search_results`,
				{
					headers: authenticatedheaders(token),
				});
			return response.status === 200 ? response.data : [];
		} catch (error) {
			console.error('Error in API call:', error);
			throw error;
		}
	}

	static async RejectSearchResult(token, search_result_id) {
		try {
			const response = await axios.post(`${BASE_URL}staff/search_results/${search_result_id}`,
				{ state: "REJECTED" }, {
				headers: authenticatedheaders(token),
			});

			const { task_id } = response.data;
			return task_id;
		} catch (error) {
			console.error('Error in API call:', error);
			throw error;
		}
	}

	static async AddAllApplicationsWithSearchResult(token, resource_request_id) {
		try {
			const response = await axios.post(`${BASE_URL}staff/resource_request/${resource_request_id}/generate_from_search_results`,
				null, {
				headers: authenticatedheaders(token),
			});
			return response.status === 202 ? response.data : [];
		} catch (error) {
			console.error('Error in API call:', error);
			throw error;
		}
	}

	static async CreateClientCall(token, data) {
		const completeUrl = `${BASE_URL}staff/resource_request/client_call/`;

		const headers = {
			Authorization: `Token ${token}`,
			"X-CSRFToken": getCookie("csrftoken"),
		};

		try {
			const response = await axios.post(completeUrl, data, { headers: headers });
			return response.data;
		} catch (error) {
			console.error("Error on create client call: ", error);
			return null;
		}
	}

	static async GenerateResourceRequestWithClientCall(token, callId, save) {
		const completeUrl = `${BASE_URL}staff/resource_request/client_call/${callId}`;
		const data = {
			save: save
		};
		const headers = authenticatedheaders(token);

		try {
			const response = await axios.post(completeUrl, data, { headers: headers });
			return response.data;
		} catch (error) {
			console.error("Error on generate resource request: ", error);
			return null;
		}
	}

	static async SendNewResourceRequest(token, roleInfo, benefits) {
		const headers = {
			"Content-Type": "application/json",
			Authorization: `Token ${token}`,
		};

		//turn skills into an array of numbers
		var skills_ = [];
		roleInfo.roleSkills.forEach((skill) => {
			skills_.push(skill.value);
		});

		var labels_ = [];
		roleInfo.roleLabels.forEach((label) => {
			labels_.push(label.value);
		});

		var organization = roleInfo.roleOrganization.value;

		const newResReqInfo = {
			role_name: roleInfo.roleName,
			organization_profile: organization,
			quantity: roleInfo.roleQuantity,
			modality: roleInfo.roleModality,
			seniority: roleInfo.roleSeniority,
			skills: skills_,
			labels: labels_,
			position_overview: roleInfo.roleDescription,
			benefits: benefits,
			start_date: roleInfo.roleStartDate,
			location: roleInfo.roleLocation,
			additional_details: roleInfo.roleDetails,
		};

		console.log(roleInfo.clientCall);
		if (roleInfo.clientCall && roleInfo.clientCall >= 0)
			newResReqInfo.client_call = roleInfo.clientCall
		console.log(newResReqInfo);
		const completeUrl = `${BASE_URL}staff/resource_request/organization`;

		try {
			const response = await axios.post(completeUrl, newResReqInfo, {
				headers: headers,
			});
			if (response.status === 201 || response.status === 200) {
				return response.data;
			} else {
				console.log("response", response);
				return null;
			}
		} catch (error) {
			console.log("data", newResReqInfo);
			console.log("error", error);
			return null;
		}
	}

	static async GetContractors(token, skills) {
		try {
			const response = await axios.post(
				`${BASE_URL}staff/contractor`,
				{ skills },
				{
					headers: authenticatedheaders(token),
				}
			);
			console.log(response.data);
			return response.data;
		} catch (error) {
			console.error('Error fetching contractors:', error);
			return false;
		}
	}

	static async GetContractorFields(token) {
		try {
			const response = await axios.get(`${BASE_URL}staff/contractor/fields`, {
				headers: authenticatedheaders(token),
			});
			return response.data;
		} catch (error) {
			console.error('Error fetching contractor fields:', error);
			return false;
		}
	}

	static async UpdateContractor(token, contractor, id){
        try {
			const formData = new FormData();
			//turn contractor into form data
			for (const key in contractor) {
				if(contractor[key] != "")
					formData.append(key, contractor[key]);
			}

			formData.set("skills", [...contractor.skills])

			if (contractor.profile_picture && typeof contractor.profile_picture === "object") {
				//add a timestamp to the name of the file to prevent overriding existing ones
				const timestamp = new Date().getTime();
				const fileName = contractor.profile_picture.name.split(".")[0] + "-" + timestamp + "." + contractor.profile_picture.name.split(".")[1];
				formData.set("profile_picture", contractor.profile_picture, fileName);
			} else {
				formData.delete("profile_picture")
			}

			if (contractor.cv && typeof contractor.cv === "object") {
				const timestamp = new Date().getTime();
				const fileName = contractor.cv.name.split(".")[0] + "-" + timestamp + "." + contractor.cv.name.split(".")[1];
				formData.set("cv", contractor.cv, fileName);
			} else {
				formData.delete("cv")
			}

			console.log("Contractor form", formData);

            const response = await axios.post(`${BASE_URL}staff/contractor/${id}/edit`, formData, {
                headers: authenticatedheaders(token, true),
            });
            return response.data;
        } catch (error) {
            console.error('Error updating contractor:', error);
            return false;
        }
    }

	static async CheckTaskStatus(token, task_id) {
		try {
			const response = await axios.get(`${BASE_URL}staff/tasks/${task_id}`, {
				headers: authenticatedheaders(token),
			});

			return response.data;
		} catch (error) {
			console.error('Error checking task status:', error);
			throw error;
		}
	}

}
