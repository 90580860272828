import { strings } from "../strings";
import { useState, useContext } from "react";
import { SignIn } from "../components/SignIn";
import { SignUp } from "../components/SignUp";
import { UserContext } from "../Providers/UserContext";
import { ComunaLogo } from "../components/comunaLogo";

export function LoginView() {
	const [currentForm, setCurrentForm] = useState("SignIn");
	const { setToken }: any = useContext(UserContext);

	const logo = require("../img/muna-logo.png");
	const img1 = require("../img/graphics/graphic_1.jpg");

	function onSignedIn(newToken: string) {
		setToken(newToken);
		window.location.reload();
	}

	return (
		<div id="login-view" className="h-full">
			<div className="w-full h-full">
				<div className="baseContent flex flex-row  h-full place-content-center">
					<div className="graphic w-1/2 flex flex-col h-full text-center items-center justify-center">
						<img
							src={img1}
							style={{ width: "50%", height: "auto", minWidth: 400 }}
							alt="people smiling and decorative colored shapes"
						/>
						<p
							style={{ width: "50%", height: "auto" }}
							className="comuna-purple font-bold text-left mt-8 text-2xl"
							dangerouslySetInnerHTML={{
								__html: strings.login_screen_copy_clients,
							}}></p>
					</div>

					<div className="w-1/2 px-4 bg-white">
						<div
							style={{
								maxWidth: 512,
								margin: "auto",
								paddingTop: 90,
							}}>
							<div className="flex flex-row justify-between items-center">
								<ComunaLogo />
								<div>
									{/* <button
										className={
											currentForm === "SignIn" ? "tabLabelSelected" : "tabLabel"
										}
										onClick={() => setCurrentForm("SignIn")}>
										Sign In
									</button> */}
									{/* <span className="mx-10 color-gray-4 text-2xl">|</span>
									<button
										className={
											currentForm === "SignUp" ? "tabLabelSelected" : "tabLabel"
										}
										onClick={() => setCurrentForm("SignUp")}>
										Sign Up
									</button> */}
								</div>
							</div>

							<div className="flex content-center flex-wrap flex-grow">
								{currentForm === "SignIn" ? (
									<SignIn onSignedInEvent={onSignedIn} />
								) : (
									<SignUp onSignedInEvent={onSignedIn} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
