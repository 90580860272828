import React, { useState, useEffect } from 'react';
import { Category } from '../../types/roleTypes';

type PricingTableProps = {
  categories: Category[];
  planSelected: string;
  onPlanSelect: (selectedPlan: string, selectedBenefits: number[]) => void;
  initialSelectedBenefits?: Record<number, number>;
};

const BenefitSelector = ({ optionsForPlan, plan, selectedPlan, onChange, initialSelectedOption, benefitName, onBenefitSelectorChange }: any): any => {
  const [selectedOption, setSelectedOption] = useState<number>(
    initialSelectedOption !== undefined && initialSelectedOption !== null
      ? initialSelectedOption
      : optionsForPlan[0][plan].id
  );

  useEffect(() => {
    if (plan !== selectedPlan) return;
    const optionsIds = optionsForPlan.map((opt: any) => opt[plan] ? opt[plan].id : null);
    onChange(optionsIds, selectedOption);
  }, [selectedPlan]);

  useEffect(() => {
    onBenefitSelectorChange(plan, benefitName, selectedOption);
  }, [selectedOption])


  return (
    <select
      value={selectedOption}
      onChange={(e) => {
        const i = parseInt(e.target.value);
        setSelectedOption(i);

        if (plan !== selectedPlan) return;

        const optionsIds = optionsForPlan.map((opt: any) => (opt[plan] ? opt[plan].id : null));
        onChange(optionsIds, i);
      }}
      className="w-full"
    >
      {optionsForPlan.map((opt: any, idx: number) => (
        <option key={idx} value={opt[plan].id}>
          {opt[plan].description || '✔️'}
        </option>
      ))}
    </select>
  );
};

const PricingTable: React.FC<PricingTableProps> = ({ categories, onPlanSelect, planSelected, initialSelectedBenefits }) => {
  const plans = ['Essentials', 'Standard', 'Premium'];

  const [selectedBenefits, setSelectedBenefits] = useState<number[]>([]);
  const [benefitSelectorState, setBenefitSelectorState] = useState<Record<string, Record<string, number>>>({});

  const bookIcon = require('../../img/icons/book-icon.png');
  const laptopIcon = require('../../img/icons/laptop-icon.png');
  const heartIcon = require('../../img/icons/heart-icon.png');
  const socialIcon = require('../../img/icons/social-icon.png');

  const essentials_icon = require('../../img/icons/plan_icon_essential.png');
  const standard_icon = require('../../img/icons/plan_icon_standard.png');
  const premium_icon = require('../../img/icons/plan_icon_premium.png');

  const check_icon = require('../../img/icons/check-circle.png');

  useEffect(() => {
    const selected: number[] = [];
    const initialState: Record<string, Record<string, number>> = {};

    categories.forEach((category) => {
      category.benefits.forEach((benefit) => {
        plans.forEach((plan) => {
          const optionsForPlan = benefit.options.filter((opt) => opt[plan]);

          if(optionsForPlan.length > 1 ){
            initialState[plan] = {
              ...initialState[plan],
              [benefit.name]: optionsForPlan[0][plan].id,
            };
          }

          if(plan == planSelected){
            if (optionsForPlan.length === 1) {
              selected.push(optionsForPlan[0][planSelected].id);
            } else if (optionsForPlan.length > 1 && initialSelectedBenefits) {
              const selectedOption = optionsForPlan.find((opt: any) => {
                const optionPlan = opt[planSelected];
                return optionPlan && initialSelectedBenefits[optionPlan.id] !== undefined;
              });
    
              if (selectedOption) {
                selected.push(selectedOption[planSelected].id);
                initialState[planSelected] = {
                  ...initialState[planSelected],
                  [benefit.name]: selectedOption[planSelected].id,
                };
              } else {
                selected.push(optionsForPlan[0][planSelected].id);
              }
            }    
          }
        });

      });
    });

    setBenefitSelectorState(initialState);
    setSelectedBenefits(selected);
    onPlanSelect(planSelected, selected);
  }, []);

  const handlePlanSelect = (plan: string) => {
    const selected: number[] = [];

    categories.forEach((category) => {
      category.benefits.forEach((benefit) => {
        const optionsForPlan = benefit.options.filter((opt) => opt[plan]);
        if (optionsForPlan.length === 1) {
          selected.push(optionsForPlan[0][plan].id);
        }
      });
    });

    setSelectedBenefits(selected);
    onPlanSelect(plan, selected);
  };

  const HandleOptionSelectedAndPlan = (allOptions: number[], optSelected: number) => {
    const newSelected = [...selectedBenefits];

    allOptions.forEach((opt) => {
      const index = newSelected.indexOf(opt);
      if (index > -1) {
        newSelected.splice(index, 1);
      }
    });

    newSelected.push(optSelected);
    setSelectedBenefits(newSelected);
    onPlanSelect(planSelected, newSelected);
  };

  const HandleOptionSelected = (plan: string, benefitName: string, optSelected: number) => {
    setBenefitSelectorState((prevState) => ({
      ...prevState,
      [plan]: {
        ...prevState[plan],
        [benefitName]: optSelected,
      },
    }));
  };

  const calculateMonthlyCost = (plan: string) => {
    let totalCost = 0;
    categories.forEach((category) => {
      category.benefits.forEach((benefit) => {
        if (!benefit.one_time_only) {
          const optionsForPlan = benefit.options.filter((opt) => opt[plan]);
          if (optionsForPlan.length > 0) {
            let selectedOption;

            if (optionsForPlan.length === 1) {
              selectedOption = optionsForPlan[0][plan];
            } else {
              const selectedOptionId = benefitSelectorState[plan]?.[benefit.name];
              if (selectedOptionId) {
                const selectedOpt = optionsForPlan.find((opt) => opt[plan].id === selectedOptionId);
                selectedOption = selectedOpt ? selectedOpt[plan] : optionsForPlan[0][plan];
              } else {
                selectedOption = optionsForPlan[0][plan];
              }
            }

            totalCost += selectedOption.cost;
          }
        }
      });
    });
    return totalCost;
  };

  const calculateOneTimeCost = (plan: string) => {
    let totalCost = 0;
    categories.forEach((category) => {
      category.benefits.forEach((benefit) => {
        if (benefit.one_time_only) {
          const optionsForPlan = benefit.options.filter((opt) => opt[plan]);
          if (optionsForPlan.length > 0) {
            let selectedOption;

            if (optionsForPlan.length === 1) {
              selectedOption = optionsForPlan[0][plan];
            } else {
              if (benefitSelectorState[plan] && benefitSelectorState[plan][benefit.name]) {
                const selectedOpt = optionsForPlan.find((opt) => opt[plan].id === benefitSelectorState[plan][benefit.name]);
                selectedOption = selectedOpt ? selectedOpt[plan] : optionsForPlan[0][plan];
              } else {
                selectedOption = optionsForPlan[0][plan];
              }
            }

            totalCost += selectedOption.cost;
          }
        }
      });
    });
    return totalCost;
  };

  const CategoryImage = ({ category }: any): any => {
    if (category === 'Equipment and Office')
      return <img src={laptopIcon} alt="category" className="mr-2 inline-block" style={{ width: 24 }} />;
    if (category === 'Health and Wellbeing')
      return <img src={heartIcon} alt="category" className="mr-2 inline-block" style={{ width: 24 }} />;
    if (category === 'Learning and Development')
      return <img src={bookIcon} alt="category" className="mr-2 inline-block" style={{ width: 24 }} />;
    if (category === 'Social and Community')
      return <img src={socialIcon} alt="category" className="mr-2 inline-block" style={{ width: 24 }} />;
  };

  const PlanHeader = ({ plan, monthly_cost, one_time_cost }: any): any => {
    let classname = 'flex flex-row cursor-pointer p-4 relative w-full h-full';

    let icon = essentials_icon;
    if (plan === 'Essentials') classname += ' rounded-tl-2xl';
    if (plan === 'Standard') icon = standard_icon;
    if (plan === 'Premium') {
      icon = premium_icon;
      classname += ' rounded-tr-2xl';
    }

    return (
      <div className={classname} style={{ backgroundColor: planSelected === plan ? '#DEF2B1' : '#F7FCEC' }}>
        <div className="flex items-stretch w-full h-full">
          <img src={icon} alt="icon" className="mr-2" style={{ width: 44, height: 38 }} />
          <div className="text-left ">
            <b>{plan}</b>
            <br />
            <span className="font-normal">${one_time_cost.toLocaleString()} (One time)</span>
            <br />
            <span className="font-normal">${monthly_cost.toLocaleString()}/Month</span>
          </div>
          <div className={'comuna-checkmark ' + (plan === planSelected && 'selected')}></div>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full max-h-[700px] overflow-y-auto">
      <table className="min-w-full border-collapse">
        <thead className="sticky top-0 bg-white z-10 px-4 py-2">
          <tr>
            <th className="" style={{ width: '11.11%' }}> </th>
            {plans.map((plan) => (
              <th
                key={plan}
                onClick={() => handlePlanSelect(plan)}
                style={{ width: '22.22%' }}
              >
                <PlanHeader plan={plan} monthly_cost={calculateMonthlyCost(plan)} one_time_cost={calculateOneTimeCost(plan)} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {categories
            .filter((category) => category.benefits.length > 0)
            .map((category, index) => (
              <React.Fragment key={category.name}>
                {index > 0 && (
                  <tr className="h-6">
                    <th style={{ width: '11.11%' }}></th>
                    <th className={(planSelected === 'Essentials' ? 'bg-comuna-lime' : '')} style={{ width: '22.22%' }}></th>
                    <th className={(planSelected === 'Standard' ? 'bg-comuna-lime' : '')} style={{ width: '22.22%' }}></th>
                    <th className={(planSelected === 'Premium' ? 'bg-comuna-lime' : '')} style={{ width: '22.22%' }}></th>
                  </tr>
                )}
                <tr className="mt-6">
                  <th className="px-4 py-2 text-left rounded-tl-lg bg-gray-0 font-medium" style={{ width: '11.11%' }}>
                    <CategoryImage category={category.name} />
                    {category.name}
                  </th>
                  <th className={(planSelected === 'Essentials' ? 'bg-comuna-lime' : 'bg-gray-0')} style={{ width: '22.22%' }}></th>
                  <th className={(planSelected === 'Standard' ? 'bg-comuna-lime' : 'bg-gray-0')} style={{ width: '22.22%' }}></th>
                  <th className={(planSelected === 'Premium' ? 'bg-comuna-lime' : 'bg-gray-0')} style={{ width: '22.22%' }}></th>
                </tr>
                {category.benefits.map((benefit, index) => (
                  <tr key={benefit.name}>
                    <td className="px-4 py-2 border-gray-0" style={{ width: '11.11%' }}>
                      {benefit.name}
                    </td>

                    {plans.map((plan) => {
                      let classname = 'px-4 py-2 text-center';

                      if (planSelected === plan) {
                        classname += ' bg-comuna-lime';
                      } else {
                        classname += ' border-gray-0';
                      }
                      const optionsForPlan = benefit.options.filter((opt) => opt[plan]);

                      if (optionsForPlan.length === 0) {
                        return (
                          <td key={plan} className={classname} style={{ width: '22.22%' }}>
                            -
                          </td>
                        );
                      }

                      if (optionsForPlan.length === 1) {
                        const description = optionsForPlan[0][plan].description;
                        if (description.length > 1) {
                          return (
                            <td key={plan} className={classname} style={{ width: '22.22%' }}>
                              {optionsForPlan[0][plan].description}
                            </td>
                          );
                        } else {
                          return (
                            <td key={plan} className={classname} style={{ width: '22.22%' }}>
                              <img src={check_icon} alt="check-icon" className="mx-auto" style={{ width: 24 }} />
                            </td>
                          );
                        }
                      }

                      if (optionsForPlan.length > 1) {
                        const selectedOptionId =
                          initialSelectedBenefits && planSelected === plan
                            ? optionsForPlan.find((opt: any) => {
                              const optionPlan = opt[plan];
                              return optionPlan && initialSelectedBenefits[optionPlan.id] !== undefined;
                            })?.[plan]?.id
                            : -1;

                        return (
                          <td key={plan} className={classname} style={{ width: '22.22%' }}>
                            <BenefitSelector
                              optionsForPlan={optionsForPlan}
                              plan={plan}
                              selectedPlan={planSelected}
                              initialSelectedOption={selectedOptionId !== -1 ? selectedOptionId : undefined}
                              onChange={HandleOptionSelectedAndPlan}
                              benefitName={benefit.name}
                              onBenefitSelectorChange={HandleOptionSelected}
                            />
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </React.Fragment>
            ))}
        </tbody>
      </table>
    </div>
  );

};

export default PricingTable;
